import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Styled
import styled from "styled-components";
import theme from "theme";

//Actions
import { sendProxy } from "../../../actions/proxy";

// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";

// Components
import Select from "react-select";
import SidePageLayout, { Info } from "../../Layout/SidePage";

const Error = styled.span`
  font-size: 1.1em;
  color: ${theme.red};
`;

const SelectStyled = styled(Select)`
  margin-bottom: 20px;
  margin-top: 8px;
`;

const Title = styled.span`
  margin-bottom: 4px;
`;

const AddProxy = ({ options, secondOptions, setSecondOptions, setOpen }) => {
  const [errors, setErrors] = React.useState([]);
  const [selectedMandatary, setSelectedMandatary] = React.useState(null);
  const [selectedMandating, setSelectedMandating] = React.useState(null);

  const { selectedSession } = useSelector((state) => state.session);
  const { allMandataries } = useSelector((state) => state.proxy);

  const dispatch = useDispatch();
  const sendProxyAction = (sessionId, mandataryId, mandateId) =>
    dispatch(sendProxy(sessionId, mandataryId, mandateId));

  useEffect(() => {
    if (selectedMandatary) {
      const secondOptions = options.filter((opt) => {
        if (
          selectedMandatary.value !== opt.value &&
          !allMandataries.find((mandatary) => mandatary.userId === opt.value)
        ) {
          return { value: opt.value, label: opt.label };
        }
      });
      setSecondOptions(secondOptions);
    }
  }, [selectedMandatary]);

  const close = () => {
    setOpen(false);
    setSelectedMandatary(null);
    setSelectedMandating(null);
    setErrors([]);
  };

  const changeMandatary = (opt) => {
    if (opt.value == selectedMandating?.value) {
      setSelectedMandating(null);
    }
    setSelectedMandatary(opt);
  };

  const send = () => {
    if (!selectedMandatary || !selectedMandating) {
      setErrors(["Veuillez sélectionner un mandataire et un mandant"]);
    } else if (selectedSession && selectedMandatary && selectedMandating) {
      sendProxyAction(
        selectedSession.id,
        selectedMandatary.value,
        selectedMandating.value
      );
      setOpen(false);
    }
  };

  return (
    <SidePageLayout
      title={<FormattedMessage {...messages.add_proxy} />}
      buttonMessage={<FormattedMessage {...messages.add_this_proxy} />}
      onValid={send}
      onClose={close}
    >
      <Info>
        <FormattedMessage {...messages.info} />
      </Info>
      <Title>
        <FormattedMessage {...messages.title_select_mandatary} />
      </Title>
      <SelectStyled
        options={options}
        onChange={(opt) => changeMandatary(opt)}
        value={selectedMandatary}
      />
      <Title>
        <FormattedMessage {...messages.title_select_mandating} />
      </Title>
      <SelectStyled
        options={secondOptions}
        onChange={(opt) => setSelectedMandating(opt)}
        value={selectedMandating}
      />
      {errors && errors.length ? (
        <Error>
          {errors.map((error) => (
            <span>{error}</span>
          ))}
        </Error>
      ) : null}
    </SidePageLayout>
  );
};

export default AddProxy;
