import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Tools
import { navigate } from "gatsby";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import globalMessages from "containers/messages"
// Components
import Button from "components/Button/Button";
import Icon from "utils/icon";
import Spinner from "components/Spinner/Spinner";
import AddProxy from "./AddProxy";
// Containers
import TopBarLayout from "../../Layout/Topbar/topBar";
import styled from "styled-components";
import theme from "theme";
import GoBack from "../../../components/GoBack/GoBack";
// Actions
import {
  getAllUsersFromUserList,
  resetUsers,
  deleteProxy,
  resetProxy,
} from "../../../actions/proxy";
import { displayName } from "../../../utils/displayUserName";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: background 0.5s ease-in-out;

  @media(max-width: 768px) {
    height: 100%;
  }
`;

const DivReturn = styled.div`
    width: 100%;
    border-bottom: 1px solid ${theme.ui.grey};
`;

const Return = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 32px;

  @media(max-width: 768px) {
    padding: 16px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 32px;
  justify-content: start;
  height: 85%;

  @media(max-width: 768px) {
    margin: 16px;
  }
`;

const AddProxySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Info = styled.span`
  color: ${theme.gray};
  margin-bottom: 8px;
  font-style: italic;
  font-size: 14px;
  margin-left: 8px;
  `;

const NotValidate = styled.span`
  color: ${theme.red};
`

const ProxyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  color: darkslategray;
  margin-top: 16px;
`;

const ProxyContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  height: 100%;

  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const WithProxy = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  @media(max-width: 768px) {
    margin-right: 0;
    padding-bottom: 16px;
  }
`;

const WithoutProxy = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  border-left: 1px dashed ${theme.ui.grey};

  @media(max-width: 768px) {
    border-left: 0;
    border-top: 1px dashed ${theme.ui.grey};
    padding: 16px 0 0 0;
  }
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  margin-top: 16px;
  align-content: flex-start;
`;

const ProxiesContent = styled(UserContent)`
  flex-direction: column;
`;

const UserCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.lightgray};
  border: 1px solid ${theme.ui.grey};
  border-radius: 5px;
  padding: 8px;
  margin: 0 8px 8px 0;
`;

const UserText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProxyCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.lightgray};
  border: 1px solid ${theme.ui.grey};
  border-radius: 5px;
  overflow: hidden;
  padding: 8px;
  margin-bottom: 8px;
`;

const MandatingSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
`;

const DeleteSection = styled.div`
  padding: 8px;
  background-color: ${theme.red};
  border-radius: 5px;
  color: white;
  cursor: pointer;
`;

const Mandating = styled.span`
  color: ${theme.blue};
  margin-top: 8px;
  font-size: 12px;
`;

const Mandatary = styled.span`
  font-size: 16px;
`;
const SlideBarBackground = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) => (props.open ? "rgba(9,67,103,.3)" : "")};
  z-index: ${(props) => (props.open ? "2" : "-1")};
`;

export default ({}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [secondOptions, setSecondOptions] = React.useState([]);
  const [cardHover, setCardHover] = React.useState(null);

  // bind state
  const { selectedSession } = useSelector((state) => state.session);
  const {
    allUsersWithoutMandating,
    otherUsers,
    fetchingAllUsers,
    proxies,
    fetchingProxy,
  } = useSelector((state) => state.proxy);
  // bind actions
  const dispatch = useDispatch();
  const getAllUsersAction = (userLists, sessionId) =>
    dispatch(getAllUsersFromUserList(userLists, sessionId));
  const resetUsersAction = () => dispatch(resetUsers());
  const resetProxyAction = () => dispatch(resetProxy());

  const deleteProxyAction = (proxy) =>
    dispatch(deleteProxy(proxy, selectedSession.userLists, selectedSession.id));

  useEffect(() => {
    if (selectedSession?.userLists?.length && !fetchingAllUsers) {
      getAllUsersAction(selectedSession.userLists, selectedSession.id);
    }
  }, []);

  useEffect(() => {
    if (!selectedSession) {
      navigate("/s");
    }
  }, [selectedSession]);

  useEffect(() => {
    if (allUsersWithoutMandating) {
      const options = allUsersWithoutMandating.map((user) => {
        return { value: user.userId, label: displayName(user) };
      });
      setOptions(options);
    }
  }, [allUsersWithoutMandating]);

  const reset = () => {
    resetUsersAction();
    resetProxyAction();
    navigate("/s");
  };

  return (
    <Container>
      <TopBarLayout />
      <DivReturn>
        <Return onClick={() => reset()}>
          <Icon icon="LeftArrow" size="sm" />
          <FormattedMessage {...globalMessages.return} />
        </Return>
      </DivReturn>
      <Content>
        <AddProxySection>
          {(!fetchingProxy && !fetchingAllUsers && (
            <>
              <Button action={() => setOpen(true)}>
                <FormattedMessage {...messages.add_proxy} />
              </Button>
              <Info>
                <FormattedMessage {...messages.not_validate} />
              </Info>
            </>
          )) ||
            null}
        </AddProxySection>
        <ProxyList>
          <Title>
            <FormattedMessage {...messages.title_list_proxy} />
          </Title>
        </ProxyList>
        <ProxyContent>
          <WithProxy>
            <span>
              <FormattedMessage {...messages.with_proxy} />
            </span>
            <ProxiesContent>
              {fetchingProxy || fetchingAllUsers ? (
                <Spinner>
                  <Icon icon="LoadingHalf" />
                </Spinner>
              ) : (
                (proxies?.length &&
                  proxies?.map((proxy) => {
                    return (
                      <ProxyCard>
                        <MandatingSection>
                          <Mandatary>
                            {displayName(proxy.mandatary)}
                            {!proxy?.mandatary?.isValidate && (
                              <NotValidate>*</NotValidate>
                            )}
                          </Mandatary>
                          <Mandating>
                            + {displayName(proxy.mandating)}
                            {!proxy?.mandating?.isValidate && (
                              <NotValidate>*</NotValidate>
                            )}
                          </Mandating>
                        </MandatingSection>
                        <DeleteSection
                          display={proxy.id == cardHover}
                          onClick={() => deleteProxyAction(proxy)}
                        >
                          <Icon icon="Cross" size="xs" color="white" />
                        </DeleteSection>
                      </ProxyCard>
                    );
                  })) || (
                  <span>
                    <FormattedMessage {...messages.no_proxy} />
                  </span>
                )
              )}
            </ProxiesContent>
          </WithProxy>
          <WithoutProxy>
            <span>
              <FormattedMessage {...messages.without_proxy} />
            </span>
            <UserContent>
              {fetchingProxy || fetchingAllUsers ? (
                <Spinner>
                  <Icon icon="LoadingHalf" />
                </Spinner>
              ) : (
                (otherUsers?.length &&
                  otherUsers.map((user) => (
                    <UserCard>
                      <UserText>
                        {displayName(user)}
                        {!user?.isValidate && <NotValidate>*</NotValidate>}
                      </UserText>
                    </UserCard>
                  ))) || (
                  <span>
                    <FormattedMessage {...messages.no_user} />
                  </span>
                )
              )}
            </UserContent>
          </WithoutProxy>
        </ProxyContent>
      </Content>
      <SlideBarBackground open={open} />
      {open && (
        <AddProxy
          options={options}
          secondOptions={secondOptions}
          setSecondOptions={setSecondOptions}
          setOpen={setOpen}
        />
      )}
    </Container>
  );
};
