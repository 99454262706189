//Local
import { A2VOTE_API } from "utils/config";
import { getAuthorization, getApiRoot } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";
//Reducer
import {
  LOAD_USERS_START,
  LOAD_USERS_END,
  RESET_USERS,
  RESET_PROXY,
  SEND_PROXY_START,
  GET_PROXIES_START,
  GET_PROXIES_END,
  DELETE_PROXY_START,
} from "src/state/proxy";

export const getAllUsersFromUserList = (userLists, sessionId) => {
  return (dispatch) => {
    dispatch({ type: LOAD_USERS_START });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
    };
    fetch(
      `${A2VOTE_API}/user-list/users/id?ids[]=${userLists
        .map((ul) => ul.id)
        .join("&ids[]=")}`,
      requestedOptions
    )
      .then(handleResponse)
      .then((res) => {
        const { status, users } = res;
        dispatch({ type: LOAD_USERS_END, users });
        dispatch(getAllProxies(sessionId));
      })
      .catch(handleCatch);
  };
};

export const resetUsers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USERS });
  };
};

export const resetProxy = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PROXY });
  };
};

export const sendProxy = (sessionId, mandataryId, mandateId) => {
  return (dispatch) => {
    dispatch({ type: SEND_PROXY_START });
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
      body: JSON.stringify({
        sessionId,
        mandataryId,
        mandateId,
      }),
    };
    fetch(`${A2VOTE_API}/proxy`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, proxies } = res;
        dispatch({ type: GET_PROXIES_END, proxies });
      })
      .catch(handleCatch);
  };
};

export const deleteProxy = (proxy, userLists, sessionId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_PROXY_START });
    const requestedOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
    };
    fetch(`${A2VOTE_API}/proxy/${proxy.id}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, proxies } = res;
        dispatch({ type: GET_PROXIES_END, proxies });
        dispatch(getAllUsersFromUserList(userLists, sessionId));
      })
      .catch(handleCatch);
  };
};

export const getAllProxies = (sessionId) => {
  return (dispatch) => {
    dispatch({ type: GET_PROXIES_START });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
        API_ROOT: getApiRoot(),
      },
    };
    fetch(`${A2VOTE_API}/proxy/${sessionId}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, proxies } = res;
        dispatch({ type: GET_PROXIES_END, proxies });
      })
      .catch(handleCatch);
  };
};
