import { defineMessages } from "react-intl";
export default defineMessages({
  return: {
    id: "a2vote.return",
    defaultMessage: "Return",
  },
  add_proxy: {
    id: "a2vote.add.proxy",
    defaultMessage: "Add proxy",
  },
  title_list_proxy: {
    id: "a2vote.title.list.proxy",
    defaultMessage: "List of participants in the session",
  },
  with_proxy: {
    id: "a2vote.with.proxy",
    defaultMessage: "With proxy",
  },
  without_proxy: {
    id: "a2vote.without.proxy",
    defaultMessage: "Without proxy",
  },
  no_proxy: {
    id: "a2vote.no.proxy",
    defaultMessage: "No proxy",
  },
  no_user: {
    id: "a2vote.no.user",
    defaultMessage: "No user",
  },
  title_select_mandatary: {
    id: "a2vote.title.select.mandatary",
    defaultMessage: "Select the mandatary of the proxy",
  },
  title_select_mandating: {
    id: "a2vote.title.select.mandating",
    defaultMessage: "Select the mandating of the proxy",
  },
  cancel: {
    id: "a2vote.cancel",
    defaultMessage: "Cancel",
  },
  not_validate: {
    id: "a2vote.user_not_validate",
    defaultMessage: "* The user has not yet joined the list",
  },
  add_this_proxy: {
    id: "a2vote.add.this.proxy",
    defaultMessage: "Add the proxy",
  },
  error_proxy: {
    id: "a2vote.error.proxy",
    defaultMessage: "Please select a mandatary and a mandating",
  },
  info: {
    id: "proxy.info",
    defaultMessage: ""
  }
});
