//React
import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Style
import styled from "styled-components";
//Utils
import Icon from "utils/icon";

const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.background || `null`};
  color: ${(props) => props.text || `null`};
  align-items: center;
  ${(props) => props.onClick && `cursor: pointer`}
`;

const Span = styled.span`
  margin-left: 6px;
`;

const GoBack = ({ action, backgroundColor, textColor, text }) => {
  return (
    <Container background={backgroundColor} text={textColor} onClick={action}>
      <Icon icon="ArrowLeft1" size="sm" />
      <Span>{text}</Span>
    </Container>
  );
};

GoBack.propTypes = {
  action: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default GoBack;
